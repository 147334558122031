import { lazy, Suspense, useEffect, createContext, useReducer, Fragment } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import Navbar from 'components/Navbar';
import ThemeProvider from 'components/ThemeProvider';
import { tokens } from 'components/ThemeProvider/theme';
import VisuallyHidden from 'components/VisuallyHidden';
import { useLocalStorage } from 'hooks';
import { msToNum } from 'utils/style';
import { reflow } from 'utils/transition';
import { initialState, reducer } from 'app/reducer';
import './reset.css';
import './index.css';

const EkerHome = lazy(() => import('pages/EkerHome'));
const EkerProject = lazy(() => import('pages/EkerProject'));
const EkerManagement= lazy(() => import('pages/EkerManagement'));

const Contact = lazy(() => import('pages/Contact'));
const Page404 = lazy(() => import('pages/404'));
const EkerAboutUs = lazy(() => import('pages/EkerAboutUs'));

export const AppContext = createContext();
export const TransitionContext = createContext();

const App = () => {
  const [storedTheme] = useLocalStorage('theme', 'light');
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    dispatch({ type: 'setTheme', value: storedTheme });
  }, [storedTheme]);

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      <ThemeProvider themeId={state.theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={`https://digitool.agency/`} />
      </Helmet>
      <VisuallyHidden showOnFocus as="a" className="skip-to-main" href="#MainContent">
        Skip to main content
      </VisuallyHidden>
      <TransitionGroup component="main" className="app" tabIndex={-1} id="MainContent">
        <Transition
          key={pathname}
          timeout={msToNum(tokens.base.durationS)}
          onEnter={reflow}
        >
          {status => (
            <TransitionContext.Provider value={{ status }}>
              <div className={classNames('app__page', `app__page--${status}`)}>
                <Suspense fallback={<Fragment />}>
                  <Switch location={location}>
                    {/* Intro */}
                    {/* <AuthRoute exact path="/" component={Intro} isPage="intro" /> */}

                    {/* GreyHouse */}
                    <AuthRoute exact path="/" component={EkerHome} isPage="ekerinsaat" />
                    <AuthRoute
                      exact
                      path="/yonetim"
                      component={EkerManagement}
                      isPage="ekerinsaat"
                    />
                    <AuthRoute
                      exact
                      path="/projelerimiz"
                      component={EkerProject}
                      isPage="ekerinsaat"
                    />
                    <AuthRoute
                      path="/hakkimizda"
                      component={EkerAboutUs}
                      isPage="ekerinsaat"
                    />
                    <AuthRoute path="/iletisim" component={Contact} isPage="ekerinsaat" />
                    <AuthRoute component={Page404} isPage="ekerinsaat" />
                  </Switch>
                </Suspense>
              </div>
            </TransitionContext.Provider>
          )}
        </Transition>
      </TransitionGroup>
    </Fragment>
  );
};

const AuthRoute = ({ component: C, props: cProps, ...rest }) => (
  <Layouts type={rest.layout} isPage={rest.isPage}>
    <Route {...rest} render={props => <C {...props} {...cProps} />} />
  </Layouts>
);

const Layouts = props => {
  const location = useLocation();
  const { children, isPage } = props;
  return (
    <Fragment>
      <Navbar location={location} isPage={isPage} />
      {children}
    </Fragment>
  );
};
export default App;
